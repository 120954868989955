import React from "react";
import Layout from "../components/layout";
import Card from "../components/card";
import background from "../assets/images/backpat.png";
import image from "../assets/images/de-desktop.png";
import MyButton from "../components/button";
import { navigate } from "gatsby";

const Details = () => {
  return (
    <Layout>
      <div style={styles.bButton}>
        <MyButton onClick={() => navigate("/product")}>عودة</MyButton>
      </div>
      <div style={styles.body}>
        <Card>
          <h1 style={styles.title}>
            <span style={styles.vertex}>فـيرتكس :</span> هو عـلاج من النباتات وآلاعشاب الطبية الطبيعية .. يعمل
            العلاج على أنسجة الجهاز الهضمي ولغشاء المخاطي ويعمل على آلانسجة
            ولخلاية الملتهبة في جدان الامعاء ولجهاز الهضمي ويعمل على تقوية عضلات
            جدار القولون وتحفيز إنتاج العصارة الصفراوية لتسريع عملية التخلص من
            السموم داخل القولون ولجهاز الهضمي ولامعاء كما يعمل على:
          </h1>
          <p style={styles.description}>
            1. يعمل على التخلص من احتباس الغازات داخل الجهاز الهضمي 2.علاج
            لتحفيز تقويه عضلات القولون 3.علاج لآنسجة القولون وجدار القولون
            4.علاج للغشاء المخاطي الداخلي للجهاز الهضمي 5.علاج الألياف العضلية
            الطولانية الخارجية للقولون 6.عند استخدام علاج فيرتكس سوف يعمل على
            الحد ولتخلص من آعراض القولون 7.يعمل على علاج الامساك 8.علاج لانـتفاخ
            البطن في الجهاز الهضمي 9.علاج الغازات التي تحصل اثناء عمليه التخمر
            للاطعمة داخل الامعاء 10.يعمل على الحد بشكل كبير من تقلصات الجهاز
            الهضمي 11.يعمل على التخلص من الحاله المصاحبه لمريض القولون وهي ضيق
            النفس ولخفقان القلب الوهمي 12.علاج لمتلازمة القولون العصبي ولقولون
            الهضمي
          </p>
          <img style={styles.img} src={image} alt="i" />
        </Card>
      </div>
    </Layout>
  );
};

const styles = {
  body: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    margin: 'auto',
    fontFamily: "bahij",

    padding: "20px 20px",
  },
  title:{
      fontSize: "19px"
  },
  img:{
      width: "90%",
      marginTop: "20px"

  },
  vertex:{
      color: "#164AFF",
  },
  bButton: {
    textAlign: "center",
    padding: "15px 20px",
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(255,255,255, 0.5)",
    WebkitBackdropFilter: "blur(10px)",
    backdropFilter: "blur(10px)",
    fontFamily: "bahij",
    zIndex: 10,
    borderTop: "1px solid rgba(0,0,0,0.1)",
  },
};
export default Details;

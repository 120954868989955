import React from 'react'


const Card = (props) => {
    
    return(
        <div style={props.blured ? styles.cardBlured : styles.card}>
            {props.children}
        </div>
    )
    
}

const styles = {
    card: {
        background: 'rgba(255, 255, 255, 0.6)',
        backdropFilter : 'blur(14px)',
        WebkitBackdropFilter: "blur(10px)",
        padding:'15px 15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderRadius: '20px',
        alignItems: 'center',
        minHeight: '90vh'
    },
    cardBlured: {
        background: 'rgba(255, 255, 255)',
        padding:'15px 15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderRadius: '20px',
        alignItems: 'center',
        minHeight: '90vh'
    }
    
};

export default Card;